import { makeStyles } from '@mui/styles';

import logo from '../../../assets/company/logo.svg';

const useStyles = makeStyles(theme => ({ 

    general: {
        width: '100%',
        minHeight: '100vh',
        // maxHeight: '80vh',
        // marginTop: '5em',

        backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

        // [theme.breakpoints.up('sm')]: {
        //     position: 'relative',
        // },
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '100%',
        // height: '100%',
        minHeight: '100vh',
        paddingTop: '3em',
        paddingBottom: '3em',
        paddingInline: '1em',
        background: 'rgba(255,255,255,0.9)',
        // background: `linear-gradient(${theme.colors.light_gray}, ${theme.colors.light_gray}, ${theme.colors.white})`,


        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            paddingInline: '12em',
        },
    },

    text_container: {
        margin: '2em',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            margin: '2em 2em 2em 4em',
            maxWidth: '800px',
        },
    },

    logo: {
        width: '140px',
        alignSelf: 'center',

        position: 'relative',
        // top: '0',
        // left: '0',

        [theme.breakpoints.up('md')]: {
            width: '130px',
            position: 'absolute',
            top: '0',
            left: '0',
            margin: '1.5em 0 0 1.5em',
        },
    },
    
}));
export default useStyles;