import React, { useRef, Fragment } from 'react';

import Footer from '../../components/Footer';
import WhatsApp from '../../components/WhatsApp';

import Welcome from './Welcomme';
import Argument from './Argument';
import Author from './Author';
// import Public from './Public';
import Clients from './Clients';
import Stage from './Stage';
import Solutions from './Solutions';
import Walkthrough from './Walkthrough';
import Foundation from './Foundation';
import Proposal from './Proposal';
import Benefits from './Benefits';
// import Articles from './Articles';


export default function PV() {

    //título da página
    document.title = 'Nosso Consultor';

    const proposal_view = useRef();
    const scrollToProposal = () => {
        proposal_view.current.scrollIntoView({ behavior: 'smooth' })
    }

    return(
        <Fragment>

            {/* <Articles /> */}
            <Welcome />
            <div ref={proposal_view}><Proposal source='pv'/></div>
            <Author />
            <Stage scrollToProposal={scrollToProposal}/>
            <Solutions />
            <Walkthrough scrollToProposal={scrollToProposal}/>
            <Foundation />
            <Clients />
            <Argument />
            {/* <Public /> */}
            <Benefits scrollToProposal={scrollToProposal}/>
            
            <Footer />

            <WhatsApp
                msg='Olá, gostaria de mais informações sobre a consultoria.'
                popup_msg='Tem alguma dúvida? Envie um whatsapp!'
                time_between={55000}
                time_up={5000}
            />
        </Fragment>
    );

}