import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import { Button, Box, TextField, Grid, Typography, MenuItem, CircularProgress } from '@mui/material';
import { SendRounded } from '@mui/icons-material';

import InputMask from 'react-input-mask';
import emailjs from 'emailjs-com';

// import Title from '../../../components/Title';
import ViewPort from '../../../services/ViewPort';

import useStyles from './styles';


export default function Proposal(props) {
    
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const { width } = ViewPort();
    // const sm = 600;
    const md = 960;


    // dados do formulário
    const [contactForm, setContactForm] = useState({
        name: '',
        company: '',
        email: '',
        whatsapp: '',
        type: '',
        income: '',
        challenge: '',
        source: props.source,
    });

    const income_values = [
                            'Até R$30mil / mês',
                            'De R$30mil a R$100mil / mês',
                            'De R$100mil a R$500mil / mês',
                            'De R$500mil a R$1milhão / mês',
                            'Acima de R$1milhão / mês'
                        ];

    // função que envia informações para formulário
    const [sending, setSending] = useState(false);
    const sendForm = () => {
        setSending(true);

        emailjs.send(
            'service_6b6f70e',
            'template_l85jg6h',
            contactForm,
            'Ku6WQ7StJSZqKiEzW'
        )
        .then((response) => {
            // console.log('SUCCESS!', response.status, response.text);
            navigate('/confirmacao-envio', { replace: true });
            setSending(false);
        })
        .catch((err) => {
            console.log('FAILED...', err);
            setSending(false);
        });
        
    }

    const isValidEmail = email => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    }

    const isValidWhatsapp = whatsapp => {
        return(
            whatsapp.replace(/\D/g,'').length>=11
        );
    }

    // variavel indica botao habilitado para enviar ou não
    const [form_enable, setForm_enable] = useState(false);
    useEffect(() => {
        if ((isValidEmail(contactForm.email) || isValidWhatsapp(contactForm.whatsapp))) {
            setForm_enable(true);
        } else {
            setForm_enable(false);
        }
    }, [contactForm]);
    

    return(
        <Fragment>
            <Box className={classes.general}>
                <Box className={classes.container}>

                    <Box className={classes.text_container}>

                        {/* <Typography
                            variant={width > md? 'h5':'h6'}
                            component='p'
                            align='left'
                            color={theme.colors.white}
                            sx={{ marginBottom: '0.2em', fontWeight: 'bold' }}
                            // gutterBottom
                        >
                            A Nosso Consultor cuida da
                        </Typography> */}

                        <Typography
                            variant={width>md? 'h3' : 'h4'}
                            component='p'
                            align={width>md? 'left' : 'center'}
                            color={theme.colors.dark_gray}
                            sx={{ fontWeight: 'bold' }}
                            gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                Transforme seu negócio com os projetos da <b style={{ color: '#09C1DC' }}>Nosso Consultor</b>
                            </p>
                        </Typography>

                        <Typography
                            variant={width>md? 'h5' : 'h6'}
                            component='p'
                            align={width>md? 'left' : 'center'}
                            color={theme.colors.gray}
                            // color={theme.palette.secondary.main}
                            sx={{ fontStyle: 'italic' }}
                            // gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                Comece <b>hoje</b> a transformação que sua empresa precisa para <b>crescer</b> de forma organizada e eficiente!
                            </p>
                        </Typography>

                    </Box>

                    <Box className={classes.form_container}>
                        <Typography
                                variant='h5'
                                component='p'
                                align='left'
                                color={theme.colors.dark_gray}
                                sx={{ fontWeight: 'bold' }}
                                gutterBottom
                        >
                            Solicite uma Proposta
                        </Typography>
                        

                        <Box
                                component='form'
                                noValidate
                                autoComplete='off'
                                // className={classes.form}
                        >
                            <Grid container spacing={1}>
                                
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id='form-name'
                                        label='Qual é o seu nome?'
                                        value={contactForm.name}
                                        onChange={e => setContactForm({ ...contactForm, name: e.target.value })}
                                        fullWidth
                                        // defaultValue='Nome'
                                        // variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id='form-email'
                                        label='E-mail'
                                        value={contactForm.email}
                                        onChange={e => setContactForm({ ...contactForm, email: e.target.value })}
                                        fullWidth
                                        // defaultValue='Nome'
                                        // variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <InputMask
                                        mask='(99) 999999999'
                                        value={contactForm.whatsapp}
                                        onChange={e => setContactForm({ ...contactForm, whatsapp: e.target.value })}
                                    >
                                        {
                                            () => <TextField 
                                                        id='form-whatsapp'
                                                        label='WhatsApp'
                                                        fullWidth
                                                    />
                                        }
                                    </InputMask>

                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Typography
                                        variant='body2'
                                        component='p'
                                        align='center'
                                        color={theme.colors.gray}
                                        sx={{ fontStyle: 'italic', display: form_enable? 'none' : 'block' }}
                                        // gutterBottom
                                    >
                                        Não esqueça de preencher uma forma de contato (e-mail ou whatsapp).
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id='form-company'
                                        label='Empresa'
                                        value={contactForm.company}
                                        onChange={e => setContactForm({ ...contactForm, company: e.target.value })}
                                        fullWidth
                                        // defaultValue='Nome'
                                        // variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id='form-type'
                                        label='Seguimento (ex.: petshop, dentista, loja de roupa, ...)'
                                        value={contactForm.type}
                                        onChange={e => setContactForm({ ...contactForm, type: e.target.value })}
                                        fullWidth
                                        // defaultValue='Nome'
                                        // variant='filled'
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id='form-income'
                                        label='Faturamento'
                                        value={contactForm.income}
                                        onChange={e => setContactForm({ ...contactForm, income: e.target.value })}
                                        fullWidth
                                        select
                                        // defaultValue='Selecione uma faixa de faturamento'
                                        // variant='filled'
                                    >
                                        {income_values.map((i) => (
                                            <MenuItem key={i} value={i}>{i}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id='form-challenge'
                                        label='Qual é o desafio atual da sua empresa?'
                                        value={contactForm.challenge}
                                        onChange={e => setContactForm({ ...contactForm, challenge: e.target.value })}
                                        fullWidth
                                        multiline
                                        rows={5}
                                    />
                                </Grid>

                                
                            </Grid>
                        </Box>

                        <Box className={classes.buttons}>
                            <Button
                                    variant='contained'
                                    endIcon={sending?
                                                <CircularProgress color='secondary' size='18px' />
                                            :
                                                <SendRounded />
                                            }
                                    color='primary'
                                    onClick={sendForm}
                                    disabled={!form_enable}
                                    sx={{m: '0.3em'}}
                                    // id='send_form_id'
                            >
                                Solicitar
                            </Button>
                        </Box>

                    </Box>
                </Box>

            </Box>

 
        </Fragment>
    );

}