import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import { Box, Typography, Link, Button } from '@mui/material';

import ReactPlayer from 'react-player';
import Markdown from 'react-markdown'
// import remarkGfm from 'remark-gfm'

import { AccountCircleRounded, TodayRounded, UndoRounded } from '@mui/icons-material';

import Footer from '../../components/Footer';
import FormContact from '../../components/FormContact';
import ViewPort from '../../services/ViewPort';

import logo from '../../assets/company/logo_completo.svg'

import articlesList from '../../articles/articlesList';

import useStyles from './styles';


export default function Article() {
    
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();

    const { width } = ViewPort();
    // const sm = 600;
    const md = 960;

    let { id } = useParams();
    const info = articlesList[id];

    // carrega qualquer coisa antes para não travar
    var article_image = require('.');
    if (info) { article_image = require('../../articles/images/' + info.image); }

    const [mdtext, setMdtext] = useState('')

    // carrega o artigo
    useEffect(() => {
        if (info) {
            if (info.file_name) {
                window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                import(`../../articles/files/${info.file_name}`).then((module)=>{
                    fetch(module.default)
                    .then(res => res.text())
                    .then(aux => {
                        setMdtext(aux);
                    })}
                )
            }
        } else {
            navigate('/', { replace: true });
        };
    }, []);


    // markdown render para customizar markdown
    const md_renderers = {
        p: (props) => <p className={classes.md_p}>{props.children}</p>,
        li: (props) => <li className={classes.md_li}>{props.children}</li>,
        h2: (props) => <h2 className={classes.md_h2}>{props.children}</h2>,
        h3: (props) => <h3 className={classes.md_h3}>{props.children}</h3>
    }

    return(
        <Fragment>
            {(info) &&
                <Fragment>

                    <Box className={classes.general}>
                        <Box className={classes.container}>

                            <Box className={classes.article_text} >

                                <Link 
                                    rel='noreferrer'
                                    href='/'
                                >
                                    <img className={classes.logo} src={logo} alt='nosso_consultor' />
                                </Link>
                            
                                <Typography
                                    variant='h5'
                                    component='p'
                                    align='center'
                                    color='primary'
                                    sx={{ fontWeight: 'bold' }}
                                    gutterBottom
                                >
                                    ARTIGO
                                </Typography>
                                <Typography
                                    variant={width>md? 'h3' : 'h4'}
                                    component='h1'
                                    align='center'
                                    color={theme.colors.dark_gray}
                                    sx={{ fontWeight: 'bold', pb: '0.8em' }}
                                    // gutterBottom
                                >
                                    {info.title}
                                </Typography>
                                <Box
                                    display='flex' justifyContent='center' alignItems='center'
                                    style={{ color: theme.colors.dark_gray }}
                                >
                                    
                                    <AccountCircleRounded />
                                    <p style={{ padding:'0 1em 0 0.2em', margin:0 }}>Alex Watanabe</p>
                                    <TodayRounded />
                                    <p style={{ padding:'0 0 0 0.2em', margin:0 }}>{info.date}</p>
                                </Box>
                                
                                <Box className={classes.container_video} >
                                    {(info.video)?
                                        (<Box className={classes.video} >
                                            <ReactPlayer
                                                url={info.video}
                                                controls
                                                // light
                                                width='100%'
                                                height='100%'
                                            />
                                        </Box>)
                                    :
                                        (<img
                                                // className={classes.client}
                                                src={article_image}
                                                alt={id}
                                                style={{ width: '98%' }}
                                        />)
                                    }
                                </Box>

                                <Typography
                                    variant='h6'
                                    component='p'
                                    align='left'
                                    color={theme.colors.dark_gray}
                                    sx={{ pb: '1.5em' }}
                                    // gutterBottom
                                >
                                    {info.short_description}
                                </Typography>
                                
                                {(info.file_name) && 
                                    <Box sx={{ color: theme.colors.dark_gray }} >
                                        <Markdown
                                            components={md_renderers}
                                            children={mdtext}
                                        />
                                    </Box>
                                }

                                <Box className={classes.contact}>

                                    <Box className={classes.contact_title}>
                                        <Typography
                                            variant={width>md? 'h4' : 'h5'}
                                            component='p'
                                            align={width>md? 'right' : 'center'}
                                            color={theme.colors.dark_gray}
                                            sx={{ fontWeight: 'bold' }}
                                            gutterBottom
                                        >
                                            {info.cta1}
                                        </Typography>

                                        <Typography
                                            variant={width>md? 'h6' : 'body1'}
                                            component='p'
                                            align={width>md? 'right' : 'center'}
                                            color={theme.colors.gray}
                                            // color={theme.palette.secondary.main}
                                            // sx={{ fontStyle: 'italic' }}
                                            // gutterBottom
                                        >
                                            <p style={{ margin: 0, padding: 0 }}>
                                                {info.cta2}
                                            </p>
                                        </Typography>
                                    </Box>

                                    <FormContact  title='Entre em contato' source={id}/>
                                </Box>
                            </Box>

                            <Button
                                variant='outlined'
                                endIcon={<UndoRounded />}
                                color='secondary'
                                onClick={() => navigate(-1)}
                                sx={{m: '0.3em'}}
                            >
                                Voltar
                            </Button>
                        
                        </Box>
                    </Box>

                    <Footer />

                </Fragment>
            }
        </Fragment>
    );

}
