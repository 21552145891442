import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: `linear-gradient(${theme.colors.white}, ${theme.colors.white_ish})`,
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        width: '95%',
        maxWidth: '1000px',
        
        paddingTop: '2em',
        paddingBottom: '3em',
        
        [theme.breakpoints.up('sm')]: {
            minHeight: '90vh',
            paddingTop: '6em',
            paddingBottom: '2em',
        },
    },
    
    video: {
        height: '197px',
        width: '350px',

        boxShadow: '0 0.2em 0.4em 0 rgba(100, 100, 100, 0.2), 0 0.3em 1em 0 rgba(100, 100, 100, 0.19)',

        [theme.breakpoints.up('sm')]: {
            height: '400px',
            width: '712px',

            [theme.breakpoints.up('md')]: {
                height: '500px',
                width: '890px',
            },
        },
    },
    
}));
export default useStyles;