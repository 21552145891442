import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    
    general: {
        backgroundColor: theme.palette.primary.main,
        padding: '0.1em 0.4em 0 0.4em',
        marginInline: '0.1em',
        borderRadius: '0.4em',
    }
    
}));
export default useStyles;