import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import ViewPort from '../../../services/ViewPort';

import logo from '../../../assets/company/logo_completo.svg';

import alex from '../../../assets/alex/alex.webp';

import falconi from '../../../assets/others/falconi.webp';
import usp from '../../../assets/others/usp.webp';
import fgv from '../../../assets/others/fgv.webp';
import dia from '../../../assets/others/dia.webp';
import latam from '../../../assets/others/latam.webp';
import gpa from '../../../assets/others/gpa.webp';

import useStyles from './styles';

export default function Author() {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600;

    const experience = [gpa, dia, latam, falconi, fgv, usp];


    return(
        <Box className={classes.general}>
            <Box className={classes.container}>

                <Box className={classes.block}>
                    <Box className={classes.info}>

                        <Box className={classes.presentation} >

                            <Box className={classes.founder}>
                                <img
                                        className={classes.foto}
                                        src={alex}
                                        alt='fundador_nosso_consultor'
                                />
                                {/* <Typography
                                    variant='body2'
                                    component='div'
                                    align='left'
                                    // color='primary'
                                    color={theme.colors.middle_gray}
                                    sx={{ fontStyle: 'italic',  display: (width>sm)? 'block':'none'}}
                                >
                                    <p style={{margin: 0, padding: '0.2em'}}><b>Consultor</b></p>
                                </Typography> */}
                            </Box>

                            <Box className={classes.presentation_text} >
                                <Typography
                                    variant='h6'
                                    component='p'
                                    align='left'
                                    color={theme.colors.middle_gray}
                                    sx={{ fontStyle: 'italic' }}
                                    // gutterBottom
                                >
                                    Olá, meu nome é
                                </Typography>
                                <Typography
                                    variant={width>sm? 'h3': 'h4'}
                                    component='h3'
                                    align='left'
                                    // color='primary'
                                    color={theme.colors.dark_gray}
                                    sx={{ fontWeight: 'bold' }}
                                    // gutterBottom
                                >
                                    ALEX WATANABE
                                </Typography>
                            </Box>
                        </Box>

                        <Typography
                                variant='h6'
                                component='div'
                                align='left'
                                sx={{
                                        p: (width>sm)? '1.2em 2em 2em 2em' : '0 0 1em 0',
                                        // fontStyle: 'italic',
                                    }}
                                // gutterBottom
                        >
                            <ul style={{ margin: 0, padding: (width>sm)? '0 0 0 0' : '0 0 0 1em' }}>
                                <li><b>15 Anos</b> de Experiência</li>
                                <li>Especialista em <b>Gestão Financeira</b> para o <b>Varejo</b></li>
                                <li><b>MBA</b> em Gestão Estratégica e Econômica pela FGV</li>
                                <li>Vivência Internacional em Grandes Empresas</li>
                            </ul>
                        </Typography>

                        <Typography
                                variant='h6'
                                component='div'
                                align='center'
                                sx={{ p: (width>sm)? '0 2em 1em 2em' : '0 0 1em 0' }}
                                // gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                Fundei a Nosso Consultor em <b>2019</b> com o propósito de apoiar empresas
                                de <b>comércio varejista</b> a organizar e otimizar suas <b>finanças</b>,
                                garantindo uma gestão eficiente e resultados sólidos.
                            </p>
                        </Typography>
                        
                        <Typography
                                variant='h6'
                                component='div'
                                align='center'
                                sx={{ p: (width>sm)? '0 2em 1em 2em' : '0 0 1em 0' }}
                                // gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                Com formação pela <b>USP</b> e <b>MBA em Gestão Estratégica e Econômica</b> pela <b>FGV</b>,
                                acumulo <b>15 anos de experiência em consultoria empresarial</b>, tendo ajudado empresas de
                                diferentes portes a alcançar melhores resultados, tanto no Brasil quanto no exterior.
                            </p>
                        </Typography>

                        <Box className={classes.curriculum} >
                            {experience.map((e,i) => 
                                <img
                                    className={classes.curriculum_logo}
                                    src={e}
                                    key={i}
                                    alt={'experiência'}
                                />
                            )}
                        </Box>
                        
                        <Typography
                                variant='h6'
                                component='div'
                                align='center'
                                sx={{ p: (width>sm)? '0.5em 2em 1em 2em' : '0' }}
                                gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                Agora, trago toda essa expertise para ajudar <b>pequenos e médios varejistas</b> a prosperarem com <b>soluções financeiras</b> sob medida.
                            </p>
                        </Typography>

                        <Typography
                                variant='h5'
                                component='div'
                                align='center'
                                sx={{ p: (width>sm)? '1em 2em 0 2em' : '1.5em 0 0 0' }}
                                // gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                <b>Vamos crescer juntos?</b>
                            </p>
                        </Typography>
                        

                        <img className={classes.nc_curriculum} alt='nosso_consultor' src={logo}/>
                        
                    </Box>
                </Box>

            </Box>
        </Box>
    );

}