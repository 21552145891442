import React from 'react';
import { Box } from '@mui/material';
// import { useTheme } from '@mui/styles';

import Title from '../../../components/Title';
// import ViewPort from '../../../services/ViewPort';
import CTAScroll from '../../../components/CTAScroll';

import Item from './Item';

import useStyles from './styles';

export default function Solutions(props) {

    const classes = useStyles();
    // const theme = useTheme();
    // const { width } = ViewPort();
    // const sm = 600;

    const stage = [
        {
            title: 'Diagnóstico do Resultado Financeiro',
            subtitle: 'Identifique os pontos críticos do desempenho financeiro',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Avaliamos o Demonstrativo de Resultados (DRE) da sua empresa, analisando lucratividade, rentabilidade e ponto de equilíbrio,
                para identificar alavancas de lucro e propor melhorias estratégicas que maximizem seus resultados.
            </p>,
        },
        {
            title: 'Gestão do Fluxo de Caixa e Capital de Giro',
            subtitle: 'Controle total sobre o caixa da sua empresa',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Implementamos um fluxo de caixa projetado e monitoramos o capital de giro da empresa, ajustando processos para otimizar
                sua gestão e garantir a saúde financeira do negócio, mesmo em cenários de grandes projetos.
            </p>,
        },
        {
            title: 'Controles Financeiros Essenciais',
            subtitle: 'Organização financeira eficiente para decisões estratégicas',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Estruturamos seu plano de contas, categorizamos despesas fixas e variáveis, e implementamos controles para monitoramento
                contínuo das finanças, assegurando uma visão clara e precisa da saúde financeira da sua empresa.
            </p>,
        },
        {
            title: 'Análise de Margem por Produto',
            subtitle: 'Maximize o lucro de cada produto',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Avaliamos detalhadamente as margens de lucro por produto, identificando oportunidades para otimizar vendas e melhorar
                a rentabilidade em seu portfólio.
            </p>,
        },
        {
            title: 'Raio-X do Estoque',
            subtitle: 'A saúde do estoque nas suas mãos',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Realizamos uma análise completa do giro de estoque, identificando produtos parados ou em falta, criando políticas de
                compras eficientes para garantir o equilíbrio entre oferta e demanda, sem comprometer o caixa da empresa.
            </p>,
        },
        {
            title: 'Precificação de Produtos',
            subtitle: 'Estrategicamente maximizando lucros',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Desenvolvemos um sistema de precificação baseado nos custos reais e na margem de lucro desejada, garantindo que
                seus produtos sejam competitivos no mercado e maximizem a lucratividade para o seu negócio.
            </p>,
        },
        {
            title: 'Treinamento de Gestão Financeira',
            subtitle: 'Capacite sua equipe para uma gestão eficiente',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Oferecemos treinamentos práticos e personalizados para capacitar sua equipe a gerenciar as finanças do negócio
                de forma estratégica, melhorando a tomada de decisões com base em dados sólidos.
            </p>,
        },
        {
            title: 'E Outros...',
            subtitle: 'Soluções sob medida para o seu negócio',
            desc:<p style={{ padding: 0, margin: 0 }}>
                Se não encontrou a solução ideal para sua empresa, entre em contato conosco! Na Nosso Consultor, podemos
                desenvolver projetos personalizados, adaptando nossa expertise às suas necessidades específicas para garantir
                o melhor resultado para o seu negócio.
            </p>,
        },
    ];

    return(
        <Box className={classes.general} >

            <Title
                    main='SOLUÇÕES PERSONALIZADAS'
                    description='Estratégias financeiras sob medida para o comércio'
            >
                <p style={{ padding: 0, margin: 0 }}>
                    Na Nosso Consultor, entendemos os desafios únicos que o setor varejista enfrenta em relação à gestão financeira.
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                    Oferecemos soluções estratégicas e práticas focadas em aumentar a lucratividade, melhorar o fluxo de caixa e
                    otimizar o giro de estoque. Nosso objetivo é garantir que sua empresa cresça de forma organizada e
                    eficiente, ajudando a tomar decisões mais assertivas.
                </p>

            </Title>

            <Box className={classes.solutions_container} >
                {stage.map((e,i) => 
                    <Item
                        key={i}
                        Icon={e.icon}
                        title={e.title}
                        subtitle={e.subtitle}
                        desc={e.desc}
                    />
                )}
            </Box>

            <CTAScroll
                main='SOLICITE UM ORÇAMENTO'
                secondary='SOLICITE UM ORÇAMENTO'
                text_value='E veja como podemos te ajudar'
                author={true}
                warranties={[
                    '15 anos de experiência em consultoria empresarial',
                    'Soluções práticas para a realidade do seu negócio',
                    'Consultoria personalizada e individual',
                ]}
                func={props.scrollToProposal}
            >
                {/* <p style={{ padding:0, margin: 0}}>
                    Vamos conversar e analisar o seu caso <u>juntos</u>! Assim poderemos construir um projeto
                    à quatro mãos, que melhor se adeque a <u>sua realidade e empresa</u>.
                </p> */}
            </CTAScroll>

        </Box>
    );

}