import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import useStyles from './styles';

export default function Tags(props) {
    
    const classes = useStyles();
    const theme = useTheme();
    const { text } = props;
    
    return(
        <Box className={classes.general} >
            <Typography
                    variant='caption'
                    component='p'
                    align='center'
                    color={theme.colors.white}
                    sx={{ fontWeight: 'bold' }}
            >
                {text}
            </Typography>
        </Box>
    );

}