import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import { Box, Typography } from '@mui/material';

// import Title from '../../../components/Title';
import ViewPort from '../../../services/ViewPort';
import FormContact from '../../../components/FormContact';

import logo from '../../../assets/company/logo_completo.svg'

import useStyles from './styles';


export default function Proposal(props) {
    
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const { width } = ViewPort();
    // const sm = 600;
    const md = 960;

    return(
        <Fragment>
            <Box className={classes.general}>
                <Box className={classes.container}>

                    <img className={classes.logo} alt='nosso_consultor' src={logo}/>

                    <Box className={classes.text_container}>

                        {/* <Typography
                            variant={width > md? 'h5':'h6'}
                            component='p'
                            align='left'
                            color={theme.colors.white}
                            sx={{ marginBottom: '0.2em', fontWeight: 'bold' }}
                            // gutterBottom
                        >
                            A Nosso Consultor cuida da
                        </Typography> */}

                        <Typography
                            variant={width>md? 'h2' : 'h4'}
                            component='div'
                            align={width>md? 'left' : 'center'}
                            color={theme.colors.dark_gray}
                            sx={{ fontWeight: 'bold' }}
                            gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                Consultoria Financeira <b style={{ color: '#09C1DC' }}>Especializada para o Varejo</b>
                            </p>
                        </Typography>

                        <Typography
                            variant={width>md? 'h5' : 'h6'}
                            component='div'
                            align={width>md? 'left' : 'center'}
                            color={theme.colors.gray}
                            // color={theme.palette.secondary.main}
                            sx={{ fontStyle: 'italic' }}
                            // gutterBottom
                        >
                            <p style={{ margin: 0, padding: 0 }}>
                                Domine suas finanças, maximize lucros e impulsione o crescimento do seu comércio!
                            </p>
                        </Typography>

                    </Box>

                    <FormContact title='Solicite uma Proposta' source={props.source}/>
                </Box>

            </Box>

 
        </Fragment>
    );

}