import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },

    main: {
        width: 'auto',
        maxWidth: '1200px',
        backgroundColor: theme.colors.white_ish,
        
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        
        borderRadius: '1em',
        padding: '2em 1em 2em 1em',
        margin: '3em 1em 3em 1em',
        
        [theme.breakpoints.up('sm')]: {
            minHeight: '80vh',
            // maxHeight: '600px',
            padding: '6em',
            margin: '2em 5em 5em 5em',
        },
    },

    small_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },

    image: {
        width: '320px',
        height: 'auto',
        // marginBottom: '1em',
        borderRadius: '20px',
    },

    text: {
        width: '320px',
        paddingLeft: '1em'
    },


}));
export default useStyles;