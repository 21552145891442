import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({

    general: {
        background: `linear-gradient(${theme.colors.white_ish},
        ${theme.colors.white}, ${theme.colors.white}, ${theme.colors.white})`,

        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        flexDirection: 'column',

        paddingTop: '4em',
        paddingBottom: '4em',
    },

    container: {
        width: '90%',
        maxWidth: '1200px',
        
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        
        [theme.breakpoints.up('sm')]: {
            // minHeight: '80vh',
            // maxHeight: '600px',
            // padding: '6em',
            margin: '2em 5em 2em 5em',
        },
    },

    container_tags: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingBottom: '0.5em',
    },

    card: {
        width: '280px',
        margin: '1.5em',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
    },

    card_area: {
        flexGrow: 1,
        display: 'flex!important',
        flexDirection: 'column!important',
        justifyContent: 'start!important',
    },

    // image: {
    //     width: '80%',
    //     maxWidth: '320px',
    //     height: 'auto',
    //     // marginBottom: '1em',
    //     borderRadius: '20px',
    //     paddingBottom: '1em',
       
    // },




}));
export default useStyles;