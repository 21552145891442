import React from 'react';
import { Link, Typography, Box, Divider, Button } from '@mui/material';
import { useTheme } from '@mui/styles';


// import nome from '../../assets/company/nome.svg';
import logo from '../../assets/company/logo_completo.svg'
import instagram from '../../assets/icons/instagram2.svg';
import youtube from '../../assets/icons/youtube2.svg'

import ViewPort from '../../services/ViewPort';

import useStyles from './styles';


export default function Footer2(props) {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600; 

    //CNPJ, email

    return (
        <footer className={classes.general}>

            <Box className={classes.logo_container} >
                <Link 
                    rel='noreferrer'
                    href='/'
                >
                    <img className={classes.logo} src={logo} alt='nosso_consultor' />
                </Link>
            </Box>

            
            <Box >

                <Box className={classes.links} >
                    <Button
                        variant='text'
                        onClick={props.scrollToProposal}
                    >
                        Início
                    </Button>
                    <Button
                        variant='text'
                        onClick={props.scrollToAuthor}
                    >
                        Consultor
                    </Button>
                    <Button
                        variant='text'
                        onClick={props.scrollToSolutions}
                    >
                        Soluções
                    </Button>
                    <Button
                        variant='text'
                        onClick={props.scrollToWalkthrough}
                    >
                        Como Funciona
                    </Button>
                    <Button
                        variant='text'
                        onClick={props.scrollToArticles}
                    >
                        Artigos
                    </Button>
                    <Button
                        variant='text'
                        onClick={props.scrollToClients}
                    >
                        Clientes
                    </Button>
                    <Button
                        className={classes.link}
                        variant='text'
                        onClick={props.scrollToProposal}
                    >
                        Contato
                    </Button>
                </Box>


                <Divider sx={{ pt: '0.5em'}} />
                <Box sx={{ pt: '2em'}} />

                <Typography
                        variant='body2'
                        component='div'
                        align='center'
                        color={theme.colors.middle_gray}
                        // sx={{ fontStyle: 'italic' }}
                        gutterBottom
                >
                    <div>
                        <p style={{ padding: 0, margin: 0 }}>
                            São Paulo/SP
                        </p>
                        <p style={{ padding: 0, margin: 0 }}>
                            CNPJ: 31.258.422/0001-95
                        </p>
                    </div>
                </Typography>

                <Typography
                        variant='body2'
                        component='div'
                        align='center'
                        color={theme.colors.middle_gray}
                        sx={{ fontStyle: 'italic' }}
                        // gutterBottom
                >
                    <p style={{ padding: 0, margin: 0 }}>
                        2024. Nosso Consultor
                    </p>
                </Typography>
            </Box>

            <Box className={classes.social_container} >
                <Box className={classes.social_logos_container}>
                    <Link 
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.instagram.com/nossoconsultor/'
                        // underline='hover'
                    >
                        <img className={classes.social_logos} src={instagram} alt='instagram_nosso_consultor' />
                    </Link>
                    <Link 
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.youtube.com/@NossoConsultor'
                        // underline='hover'
                    >
                        <img className={classes.social_logos} src={youtube} alt='youtube_nosso_consultor' />
                    </Link>
                </Box>

                {width>sm && 
                    <Typography
                            variant='body2'
                            component='p'
                            align='center'
                            color={theme.colors.gray}
                            sx={{ fontStyle: 'italic', fontWeight: 'bold', pt: '2em' }}
                            gutterBottom
                    >
                        Juntos, sua empresa pode ir mais longe
                    </Typography>
                }
            </Box>

            
        </footer>
    );
}