import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({ 

    form_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '95%',
        // minHeight: '100vh',
        // paddingTop: '5em',
        // paddingBottom: '1em',
        // paddingInline: '1em',
        padding: '1em',
        background: 'rgba(255,255,255,0.65)',

        border: '1px solid',
        borderColor: theme.colors.light_blue,
        borderRadius: '1em',

        [theme.breakpoints.up('md')]: {
            width: '50%',
            marginRight: '3em',
            minWidth: '500px',
        },
    },

    // form: {
    //     maxWidth: '500px',
    // },

    buttons: {
        marginTop: '1.5em',
        // marginBottom: '3em',
    }
    
}));
export default useStyles;