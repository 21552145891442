import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import ReactPlayer from 'react-player';

import Title from '../../../components/Title';
// import CTA from '../../../components/CTA';
import ViewPort from '../../../services/ViewPort';

import patricia_bomfim_arquitetura from '../../../assets/clients/patricia_bomfim_arquitetura.webp';
import dog_school from '../../../assets/clients/dog_school.webp';
import cro from '../../../assets/clients/cro.webp';
import hayati from '../../../assets/clients/hayati.webp';
import bonfim_iluminacao from '../../../assets/clients/bonfim_iluminacao.webp';
import agil_telecom from '../../../assets/clients/agil_telecom.webp'
import casa_das_organistas from '../../../assets/clients/casa_das_organistas.webp'
import onix from '../../../assets/clients/onix.webp'
import innova_safety from '../../../assets/clients/innova_safety.webp'
import petshop_fino_trato from '../../../assets/clients/petshop_fino_trato.webp'
import larbor from '../../../assets/clients/larbor.webp'
import bonfim_eletrica from '../../../assets/clients/bonfim_eletrica.webp'
import houi_produtora from '../../../assets/clients/houi_produtora.webp'
import silo_gourmet from '../../../assets/clients/silo_gourmet.webp'
import godoy from '../../../assets/clients/godoy.webp'

import useStyles from './styles';

export default function Clients() {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    const sm = 600; 

    const clients_logo = [patricia_bomfim_arquitetura, dog_school, cro, bonfim_iluminacao, hayati,
                          agil_telecom, casa_das_organistas, onix, innova_safety, petshop_fino_trato,
                          larbor, bonfim_eletrica, houi_produtora, silo_gourmet, godoy ];  


// 05) DEPOIMENTOS
// Em imagem, texto ou vídeo…
// Foto com texto?
// Patrícia -> triplicou o faturamento em 3 meses
// João Vitor -> redução do estoque em 20%
// …
// Precisa comprovar o sucesso, que deu certo
// Pegar variedade de coisas que eu resolvi… (tipos de clientes diferentes para eles se identificarem e eu mostrar que já fiz isso)


    return(
        <Box className={classes.general}>

            <Box className={classes.container}>
                
                <Title
                        main='CLIENTES'
                        description='Empresas que confiam na Nosso Consultor'
                >
                    <p>
                    Ao longo dos anos, temos ajudado diversas empresas a alcançarem seus objetivos financeiros e operacionais.
                    Cada projeto foi uma oportunidade de entregar resultados práticos e transformadores, ajustados às
                    necessidades de cada empresa.
                    </p>
                </Title>

                <Box className={classes.clients} >
                    {clients_logo.map((e,i) => 
                        <img className={classes.client} src={e} alt='clientes' key={i}/>
                    )}
                </Box>


                <Box className={classes.testimonial_video} >

                    <Box className={classes.vid_desc}>
                        <Typography
                                variant={width>sm? 'h6' : 'body1'}
                                component='div'
                                align={width>sm? 'right' : 'center'}
                                color={theme.colors.dark_gray}
                                sx={{ fontStyle: 'italic' }}
                        >
                            <p style={{ padding:0, margin: 0}}>
                                Neste vídeo, João Vitor compartilha sua <b>experiência transformadora</b> com a Nosso Consultor. 
                                Da gestão diária aos desafios estratégicos, ele detalha como nossa consultoria personalizada
                                foi essencial para o <b>crescimento e sucesso</b> do seu negócio.
                                A Nosso Consultor está comprometida em levar <b>conhecimento em gestão e administração a pequenos empresários</b>,
                                transformando desafios em oportunidades de crescimento. Este vídeo é um exemplo real do poder de uma consultoria eficaz.
                            </p>
                        </Typography>
                    </Box>

                    <Box className={classes.video} >
                        <ReactPlayer
                            url='https://youtube.com/shorts/yU8Hh2bokjw '
                            controls
                            // light
                            width='100%'
                            height='100%'
                        />
                    </Box>

                </Box>

                <Box className={classes.quote}>
                    <Typography
                            variant='h6'
                            component='div'
                            align='center'
                            sx={{ fontStyle: 'italic' }}
                            gutterBottom
                    >
                        <p style={{ padding:0, margin: 0}}>"Atingir os objetivos requer um trabalho em conjunto, consultor e cliente.
                        Ao investigar os problemas detalhadamente podemos focar nas atividades corretas.
                        Assim o resultado sempre aparece."</p>
                    </Typography>
                    <Typography
                            variant='h6'
                            component='p'
                            align='center'
                            color={theme.colors.dark_gray}
                            sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
                    >
                        - Alex Watanabe -
                    </Typography>
                </Box>

            </Box>
            
            {/*
            03) BOTÃO DE VENDAS
            CTA com impacto emocional
            Se você quer ter um negócio digital que vende muito todo santo dia e te proporciona um estilo de vida com conforto e segurança clique no link abaixo e entre no VTSD agora.
            Dê o primeiro passo para organizar sua empresa e sair do sufoco…
            [Entrar no VTSD]
            Pensar em colocar ou não o preço (como é mais barato, talvez faça sentido colocar o preço aqui) Dividir em pacotes de 3 meses, 6 meses?
            */}
            {/* <CTA
                main='ENTRAR EM CONTATO'
                secondary='ENTRAR EM CONTATO'
                text_value='Agende uma conversa gratuita'
                author={true}
                warranties={[
                    'Valor acessível para pequenas empresas',
                    'Cancele quando quiser sem multas',
                    'Consultoria individual',
                ]}
                href='/proposal'
            >
                <p style={{ padding:0, margin: 0}}>
                    Vamos conversar e analisar o seu caso <u>juntos</u>! Assim poderemos construir um projeto
                    à quatro mãos, que melhor se adeque a <u>sua realidade e empresa</u>.
                </p>
            </CTA> */}

        </Box>
    );

}