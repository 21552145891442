import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import big_company from '../../../assets/others/big_company.webp';
import small_company from '../../../assets/others/small_company.webp';

import useStyles from './styles';
import ViewPort from '../../../services/ViewPort';


export default function Argument() {
    
    const classes = useStyles();
    const theme = useTheme();
    
    const { width } = ViewPort();
    const sm = 600;
    
    return(
        <Box className={classes.general}>
            <Box className={classes.main}>
                <Typography
                    variant='h6'
                    component='h3'
                    align='center'
                    color='primary'
                    sx={{ fontWeight: 'bold' }}
                    gutterBottom
                >
                    CONSULTORIA PARA PEQUENAS EMPRESAS
                </Typography>
                <Typography
                    variant={width>sm?'h3':'h4'}
                    component='div'
                    align='center'
                    color={theme.colors.dark_gray}
                    gutterBottom
                >
                    <p style={{ padding: 0, margin: 0 }}>
                        Por que contratar uma <b>CONSULTORIA</b>?
                    </p>
                </Typography>

                <Typography
                        variant='h6'
                        component='p'
                        align='center'
                        color={theme.colors.dark_gray}
                        sx={{ fontWeight: 'bold', pb: '1em', pt: (width>sm)? '2em':'1em' }}
                        // gutterBottom
                >
                    GRANDES EMPRESAS
                </Typography>

                <Box className={classes.small_container} >
                    <img
                            className={classes.image}
                            src={big_company}
                            alt='grandes_empresas'
                    />

                    <Typography
                            variant='body1'
                            component='div'
                            align='left'
                            // gutterBottom
                            className={classes.text}
                    >
                        <p>
                            Grandes empresas possuem <b>equipes especializadas em finanças</b>, garantindo que o fluxo de caixa, a precificação
                            e a lucratividade estejam sempre alinhados com os objetivos estratégicos.
                        </p>
                    </Typography>
                </Box>


                <Typography
                        variant='h6'
                        component='p'
                        align='center'
                        color={theme.colors.dark_gray}
                        sx={{ fontWeight: 'bold', pb: '1em', pt: (width>sm)? '2em':'1em'}}
                        // gutterBottom
                >
                    PEQUENAS EMPRESAS
                </Typography>

                <Box className={classes.small_container} >

                    <img
                            className={classes.image}
                            src={small_company}
                            alt='pequenas_empresas'
                    />

                    <Typography
                        variant='body1'
                        component='div'
                        align='left'
                        className={classes.text}
                        // gutterBottom
                    >
                        <p>
                            Nos pequenos comércios, o <b>dono muitas vezes se divide entre diversas áreas</b>, incluindo as finanças,
                            sem tempo ou conhecimento técnico para garantir uma gestão eficiente.
                        </p>
                    </Typography>

                </Box>

                <Typography
                    variant='h6'
                    component='div'
                    align='center'
                    sx={{ mt: (width>sm)? '2em':'1em', paddingInline: (width>sm)? '4em':'1em' }}
                    // gutterBottom
                >
                    <p>
                        Contratar um <b>consultor financeiro especializado no varejo</b> traz ferramentas e estratégias que melhoram
                        o controle financeiro, otimizam a gestão do caixa e maximizam os lucros.
                    </p>
                </Typography>
                
            </Box>
        </Box>
    );

}