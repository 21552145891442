import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';


// ----------------- Importando  páginas -----------------

import PV from './pages/PV';
import Retail from './pages/Retail';
import ConfProposal from './pages/ConfProposal';
import Article from './pages/Article';


export default function RoutesNC() {

    return (
        <Routes>

            {/* ------------------ Link Direto ------------------ */}
            {/* <Route exact path='/' element={<PV05 />} /> */}


            {/* --------------- Redirecionamento ---------------- */}
            <Route exact path='/' element={<Navigate replace to='/varejo' />} />
            

            {/* -------------------- Páginas -------------------- */}
            <Route path='/pv' element={<PV />}/>
            <Route path='/varejo' element={<Retail />}/>
            <Route path='/confirmacao-envio' element={<ConfProposal />}/>

            {/* -------------------- Artigos -------------------- */}
            <Route path='/artigos/:id' element={<Article />}/>


            {/* se endereço errado, redireciona para / */}
            <Route path='*' element={<Navigate replace to='/' />} />

        </Routes>
    );
}   