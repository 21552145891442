import { AlignHorizontalCenter } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    
    general: {
        position: 'relative',

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        // height: '70px',
        padding: '1.5em 1em 2.5em 1em',

        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.white_ish,
        backgroundColor: theme.colors.light_gray,

        [theme.breakpoints.up('sm')]: {
            padding: '2.5em',
            flexDirection: 'row',
            alignItems: 'start',
        },
    },
    
    logo_container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '1.5em',
    },

    // info_container: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    // },

    social_container: {
        alignSelf: 'stretch',

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        flexDirection: 'column',

        position: 'absolute',
        top: '0.5em',
        right: '0.5em',

        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            top: '0',
            right: '0',
        },
    },

    links: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },


    logo: {
        width: '150px',
        // paddingBottom: '0.2em',

        [theme.breakpoints.up('sm')]: {
            width: '220px',
            // paddingBottom: '0.5em',
        },
    },

    social_logos_container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    social_logos: {
        width: '25px',
        margin: '0.2em',
        // [theme.breakpoints.up('sm')]: {
            // width: '20px',
        // },
    }

}));
export default useStyles;