import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    
    general: {
        width: '95%',
        // height: '100%',
        // position: 'relative',
        margin: '1em',
        // borderRadius: '1em!important',
        
        [theme.breakpoints.up('sm')]: {
            width: '400px',
            margin: '2em',
        },
    },
    
    container: {
        width: '100%',
        height: '100%',
        padding: '2em 1.5em 2em 1.5em',
        backgroundColor: theme.colors.light_gray,
        borderRadius: '1em',
        

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            height: '350px',
            padding: '3em 2em 3em 2em',
        },
    },
    
}));
export default useStyles;