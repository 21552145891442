import React from 'react';
import { Link, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import nome from '../../assets/company/nome.svg';
import instagram2 from '../../assets/icons/instagram2.svg';
import youtube2 from '../../assets/icons/youtube2.svg';

//import ViewPort from '../../services/ViewPort';

import useStyles from './styles';


export default function Footer(props) {

    const classes = useStyles();
    const theme = useTheme();
    //const { width } = ViewPort();
    //const sm = 600; 

    //CNPJ, email

    return (
        <footer className={classes.general}>

            <Link 
                rel='noreferrer'
                href='/'
            >
                <img className={classes.logo} src={nome} alt='nosso_consultor' />
            </Link>
            <Typography
                    variant='body2'
                    component='p'
                    align='center'
                    color={theme.colors.gray}
                    sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
                    gutterBottom
            >
                Juntos, sua empresa pode ir mais longe
            </Typography>

            <Box display='flex'>
                <Link 
                    className={classes.social_logo}
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.instagram.com/nossoconsultor/'
                >
                    <img className={classes.social_image} src={instagram2} alt='instagram_nosso_consultor' />
                </Link>
                <Link 
                    className={classes.social_logo}
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.youtube.com/@NossoConsultor'
                >
                    <img className={classes.social_image} src={youtube2} alt='instagram_nosso_consultor' />
                </Link>

            </Box>

            

            <Typography
                    variant='caption'
                    component='div'
                    align='center'
                    color={theme.colors.middle_gray}
                    // sx={{ fontStyle: 'italic' }}
                    gutterBottom
            >
                <div>
                    <p style={{ padding: 0, margin: 0 }}>
                        São Paulo/SP
                    </p>
                    <p style={{ padding: 0, margin: 0 }}>
                        CNPJ: 31.258.422/0001-95
                    </p>
                </div>
            </Typography>

            <Typography
                    variant='body2'
                    component='div'
                    align='center'
                    color={theme.colors.middle_gray}
                    sx={{ fontStyle: 'italic' }}
                    // gutterBottom
            >
                <p style={{ padding: 0, margin: 0 }}>
                    2024. Nosso Consultor
                </p>
            </Typography>
        </footer>
    );
}