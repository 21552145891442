const articlesList = {

    'qual-e-o-principal-erro-que-os-varejistas-cometem': {
        date: '12/11/2024',
        file_name: '',
        title: 'Qual é o Principal Erro que os Varejistas Cometem?',
        short_description: `Descubra como a gestão financeira pode impulsionar o seu comércio varejista! Neste vídeo, Alex Watanabe, da Nosso Consultor,
                            te mostra como tomar decisões mais inteligentes para alcançar melhores resultados.`,
        cta1: <p style={{ margin: 0, padding: 0 }}>
                  Quer implantar uma <b style={{ color: '#09C1DC' }}>gestão financeira</b> na sua empresa?
              </p>,
        cta2: 'Entre e contato e veja como podemos te ajudar.',
        video: 'https://youtu.be/5ae8HIOQKIY',
        image: 'qual-e-o-principal-erro-que-os-varejistas-cometem.webp',
        tags: ['vídeo'],
    },

    'como-organizar-o-financeiro-do-meu-comercio': {
        date: '30/10/2024',
        file_name: 'como-organizar-o-financeiro-do-meu-comercio.md',
        title: 'Como Organizar o Financeiro do meu Comércio?',
        short_description: `Se você sente que gerenciar as finanças do seu comércio é uma tarefa complexa, saiba que não está sozinho.
                            Administrar as finanças é um dos grandes desafios para comerciantes. Além de controlar o caixa da empresa,
                            é essencial monitorar resultados através do DRE (Demonstração do Resultado do Exercício) e analisar indicadores, como:
                            giro de estoque e margem de lucro. Todos esses controles e dados nos permitem ter decisões mais seguras.`,
        cta1: <p style={{ margin: 0, padding: 0 }}>
                  Quer tomar <b style={{ color: '#09C1DC' }}>decisões financeiras mais seguras</b>?
              </p>,
        cta2: 'Entre em contato e saiba como uma consultoria especializada pode apoiar o crescimento do seu negócio.',
        video: 'https://youtu.be/EgRBsi7gm2k',
        image: 'como-organizar-o-financeiro-do-meu-comercio.webp',
        tags: ['vídeo', 'artigo'],
    },

    'como-um-fluxo-de-caixa-bem-controlado-garante-o-sucesso-do-seu-comercio': {
        date: '23/10/2024',
        file_name: 'como-um-fluxo-de-caixa-bem-controlado-garante-o-sucesso-do-seu-comercio.md',
        title: 'Como um Fluxo de Caixa Bem Controlado Garante o Sucesso do Seu Comércio',
        short_description: `A gestão eficiente do fluxo de caixa é uma das principais práticas que garantem a sustentabilidade e
                            o crescimento de qualquer comércio, independentemente do seu tamanho. No comércio varejista, onde as
                            operações diárias são intensas e o volume de transações é elevado, manter um controle rigoroso sobre
                            as entradas e saídas de dinheiro pode significar a diferença entre o sucesso e o fracasso.
                            Vamos entender como um fluxo de caixa bem controlado pode garantir o sucesso do seu comércio.`,
        cta1: <p style={{ margin: 0, padding: 0 }}>
                  Quer garantir um <b style={{ color: '#09C1DC' }}>fluxo de caixa bem controlado </b>no seu comércio?
              </p>,
        cta2: 'Entre em contato com a Nosso Consultor e descubra como podemos ajudar a otimizar as finanças da sua empresa com estratégias personalizadas para garantir o sucesso e o crescimento sustentável do seu negócio.',
        video: '',
        image: 'fluxo-de-caixa.webp',
        tags: ['artigo'],
    },

    '5-indicadores-financeiros-essenciais-para-avaliar-a-saude-do-seu-negocio': {
        date: '17/10/2024',
        file_name: '5-indicadores-financeiros-essenciais-para-avaliar-a-saude-do-seu-negocio.md',
        title: '5 Indicadores Financeiros Essenciais para Avaliar a Saúde do Seu Negócio',
        short_description: `Manter a saúde financeira de uma empresa é fundamental para garantir a longevidade e crescimento
                            sustentável. Para isso, é essencial o acompanhamento contínuo de indicadores financeiros que revelem 
                            o desempenho da sua empresa. Vamos apresentar aqui cinco indicadores essenciais que todo empresário
                            deve monitorar para avaliar a saúde financeira de sua empresa.`,
        cta1: <p style={{ margin: 0, padding: 0 }}>
                  Quer melhorar a <b style={{ color: '#09C1DC' }}>gestão financeira do seu negócio </b>?
              </p>,
        cta2: 'Entre em contato com a Nosso Consultor para uma análise detalhada e personalizada da sua empresa. Vamos juntos implementar soluções financeiras eficazes que garantam o sucesso do seu negócio!',
        video: '',
        image: 'indicadores.webp',
        tags: ['artigo'],
    },

    'precificacao-correta-a-chave-para-aumentar-lucros-no-varejo': {
        date: '13/10/2024',
        file_name: 'precificacao-correta-a-chave-para-aumentar-lucros-no-varejo.md',
        title: 'Precificação Correta: A Chave para Aumentar Lucros no Varejo',
        short_description: `A precificação de produtos é um dos principais pilares para garantir a lucratividade de qualquer negócio, especialmente no comércio varejista.
                            Muitos empresários cometem o erro de definir preços baseados apenas na concorrência e no “feeling”, sem considerar todos os custos envolvidos
                            e as margens de lucro desejadas. Aqui nós vamos explorar as melhores práticas para a precificação no varejo e como elas podem te ajudar a aumentar
                            os lucros do seu negócio.`,
        cta1: <p style={{ margin: 0, padding: 0 }}>
                  Precisa de ajuda para <b style={{ color: '#09C1DC' }}>otimizar a precificação </b>dos seus produtos?
              </p>,
        cta2: 'Entre em contato com a Nosso Consultor e descubra como uma consultoria especializada pode ajudá-lo a desenvolver uma estratégia de precificação eficiente e lucrativa para o seu comércio.',
        video: '',
        image: 'precificacao.webp',
        tags: ['artigo'],

    },

  
};
export default articlesList;