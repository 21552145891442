import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import ReactPlayer from 'react-player';

import ViewPort from '../../../services/ViewPort';

import useStyles from './styles';

export default function Video() {

    const classes = useStyles();
    const theme = useTheme();
    const { width } = ViewPort();
    // const sm = 600;
    const md = 960;


    return(
        <Box className={classes.general}>
            <Box className={classes.container}>

                <Typography
                    variant={width > md? 'h4': 'h5'}
                    component='p'
                    align='center'
                    color={theme.colors.dark_gray}
                    sx={{ marginBottom: '0.5em', fontWeight: 'bold' }}
                    // gutterBottom
                >
                    Conheça a História da Nosso Consultor
                </Typography>

                <Typography
                    variant={width > md? 'h5': 'body1'}
                    component='p'
                    align='center'
                    color={theme.colors.gray}
                    sx={{ marginBottom: '2em' }}
                    // gutterBottom
                >
                        Minha trajetória na consultoria financeira começou há quase 15 anos, movida pelo desejo
                        de apoiar pequenos e médios empresários na conquista de resultados reais. <br />
                        Aqui compartilho um pouco sobre a minha jornada profissional, desde a experiência com grandes
                        empresas até a criação da Nosso Consultor.
                </Typography>

                <Box className={classes.video} >
                    <ReactPlayer
                        url='https://youtu.be/WBqRJMjSAuM'
                        controls
                        // light
                        width='100%'
                        height='100%'
                    />
                </Box>
                
            </Box>
        </Box>
    );

}