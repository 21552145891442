import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    
    general: {

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        // height: '70px',
        padding: '1.5em 1em 2.5em 1em',

        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.white_ish,
        backgroundColor: theme.colors.light_gray,

        [theme.breakpoints.up('sm')]: {
            padding: '2.5em',
        },
    },
    
    logo: {
        width: '150px',
        // paddingBottom: '0.2em',

        [theme.breakpoints.up('sm')]: {
            width: '200px',
            // paddingBottom: '0.5em',
        },
    },

    social_image: {
        width: '25px',
        //paddingRight: '0.2em',
        // [theme.breakpoints.up('sm')]: {
            // width: '20px',
        // },
    },

    social_logo: {
        padding: '0.2em',
    }

}));
export default useStyles;